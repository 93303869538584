var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('form-create',{attrs:{"components":[
          {
            name: 'form-item',
            props: { prop: 'keyword', label: '学生名称' },
          },
        ],"inline":"","labelWidth":'70px'},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}},[_c('el-form-item',{staticClass:"operate",attrs:{"slot":"after"},slot:"after"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$refs.table.reset(_vm.where)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.$refs.table.reset((_vm.where = { status: null }))}}},[_vm._v("重置")])],1)],1)],1),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":12}},[(_vm.courseInfo.is_free + '' !== 0 + '')?_c('div',[_vm._v(" 添加学生白名单："),_c('el-button',{attrs:{"disabled":!_vm.$isPowers('courseStudyCreate'),"size":"small","type":"primary"},on:{"click":function($event){_vm.$refs.dialog.visibled(
              (_vm.dialogData = { title: '添加白名单', data: {} }) && true
            )}}},[_vm._v("添加白名单")])],1):_c('div',[_vm._v(" ")])])],1),_c('com-table',{ref:"table",attrs:{"columns":[
      { prop: '', label: '电话号码', width: '150' ,align: 'center' },
      { prop: 'nickname', label: '学生姓名',align: 'center' },
      // {prop:'typeStr',label:'模式',width:'160'},
      { prop: 'statusStr', label: '状态',align: 'center' },
      { prop: 'create_time', label: '创建时间', align: 'center' },
      { prop: 'price', label: '付费金额', align: 'center' },

      {
        fixed: 'right',
        label: '操作',
        width: '500',
        slot: 'action',
        align: 'center',
      },
    ],"data":{ url: '/xapi/course.course/studentList' },"where":_vm.where},scopedSlots:_vm._u([{key:"action",fn:function({ row }){return [_c('el-button-group',[(row.status != 0 && row.status != 2)?_c('el-button',{attrs:{"type":"primary","disabled":!_vm.$isPowers('courseDrop'),"size":"mini"},on:{"click":function($event){return _vm.quitCourse(row)}}},[_vm._v("退课")]):_vm._e(),(row.status != 0 && row.status != 2)?_c('el-button',{attrs:{"type":"primary","disabled":!_vm.$isPowers('courseTransfer'),"size":"mini"},on:{"click":function($event){_vm.$refs.zkDialog.visibled((_vm.currentData = { ...row }) && true)}}},[_vm._v("转课")]):_vm._e(),(row.status == 1)?_c('el-button',{attrs:{"disabled":!_vm.$isPowers('courseStudyDel'),"type":"primary","size":"mini"},on:{"click":function($event){_vm.$handleConfirm(
              '您确定将学生「' + row.nickname + '」踢出白名单吗?',
              '/xapi/course.course/studentDel',
              { ..._vm.$route.params, user_id: row.id },
              () => {
                _vm.$refs.table.reload();
              },
              { title: '踢出白名单' }
            )}}},[_vm._v("踢出白名单")]):_vm._e(),(row.status == 1 || row.type == '')?_c('el-button',{attrs:{"type":"primary","disabled":!_vm.$isPowers('courseStudySpeed'),"size":"mini"},on:{"click":function($event){return _vm.$handleRoute(
              { study_user_id: row.id },
              'teachingCourseStudySpeed'
            )}}},[_vm._v("查看学习进度")]):_vm._e(),(row.status == 2)?_c('el-button',{attrs:{"type":"primary","disabled":!_vm.$isPowers('courseStudyDel'),"size":"mini"},on:{"click":function($event){_vm.$handleConfirm(
              '你确定删除此学生吗？',
              '/xapi/course.course/studentDel',
              { ..._vm.$route.params, user_id: row.id },
              () => {
                _vm.$refs.table.reload();
              }
            )}}},[_vm._v("删除")]):_vm._e(),(_vm.isTeach && _vm.courseInfo.learn_mode + '' === '2')?_c('el-button',{attrs:{"type":"primary","disabled":!_vm.$isPowers('courseStudyZYue'),"size":"mini"},on:{"click":function($event){_vm.$refs.ZYueDialog.visibled((_vm.currentData = { ...row }) && true)}}},[_vm._v(" 学习内容授权 ")]):_vm._e()],1)]}}])}),_c('diy-dialog',{ref:"dialog",attrs:{"title":_vm.dialogData.title,"width":"550px","center":""}},[_c('student-save',{ref:"studentSave",attrs:{"course_id":_vm.course_id},on:{"success":function($event){_vm.$refs.table.reload();
        _vm.$refs.dialog.visibled(false);}},model:{value:(_vm.dialogData.data),callback:function ($$v) {_vm.$set(_vm.dialogData, "data", $$v)},expression:"dialogData.data"}},[_c('span',{attrs:{"slot":"after"},slot:"after"})]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.$refs.dialog.visibled(false)}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$refs.studentSave.handleCommand('save')}}},[_vm._v("确 定")])],1)],1),_c('diy-dialog',{ref:"txDialog",attrs:{"title":'退课',"width":"650px","center":""}},[_c('form-create',{ref:"txForm",staticStyle:{"text-align":"left"},attrs:{"label-width":"100px","components":[
        {
          name: 'form-item',
          props: {
            prop: 'leave_type',
            label: '退课方式',
            required: true,
            type: 'radio',
            defaultValue: 1,
            data: {
              url: '/xapi/course.course/studentOptions/type/leave_type',
            },
            dataMethod(data) {
              data = data.map((d) => {
                d.disabled =
                  (!Number(_vm.currentData.price) > 0 ||
                    _vm.currentData.price == null) &&
                  ['2', '3'].indexOf(d.value) > -1;
                return d;
              });
            },
          },
        },
        {
          name: 'form-item',
          props: {
            prop: 'refund_price',
            label: '退款金额',
            required: true,
            disabled: false,
            value: 0,
            type: 'number',
            max: Number(_vm.currentData.price),
          },
          condition: '{{leave_type}}==2',
          hidden: _vm.isTeach === false,
          slots: { after: '元' },
        },
        {
          name: 'form-item',
          props: {
            prop: 'refund_price',
            label: '退款金额',
            disabled: true,
            value: _vm.currentData.price,
            type: 'number',
            max: parseFloat(_vm.currentData.price),
          },
          condition: '{{leave_type}}==3',
          hidden: _vm.isTeach === false,
          slots: { after: '元' },
        },
        {
          name: 'form-item',
          props: {
            prop: 'leave_reason',
            label: '退课理由',
            required: true,
            type: 'textarea',
          },
        },
        {
          name: 'form-item',
          props: {
            prop: 'contact_mobile',
            label: '联系方式',
            required: true,
          },
          hidden: _vm.isTeach === true,
        },
      ]}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.$refs.txDialog.visibled(false)}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleCommand('txSave')}}},[_vm._v("确 定")])],1)],1),_c('diy-dialog',{ref:"zkDialog",attrs:{"title":'转课',"width":"650px","center":""}},[_c('form-create',{ref:"zkForm",staticStyle:{"text-align":"left"},attrs:{"label-width":"100px","components":[
        {
          name: 'form-item',
          props: {
            prop: 'transfer_course_id',
            label: '选择课程',
            required: true,
            type: 'select',
            data: {
              url: '/xapi/course.course/myList',
              labelOptions: { value: 'id', label: 'course_name' },
              params: { course_id: _vm.course_id, is_plan: 0, is_up: 1 },
            },
          },
        },
        {
          name: 'form-item',
          props: {
            prop: 'transfer_reason',
            label: '转课理由',
            required: true,
            type: 'textarea',
          },
        },
        {
          name: 'form-item',
          props: {
            prop: 'contact_mobile',
            label: '联系方式',
            required: true,
          },
          hidden: _vm.isTeach === true,
        },
      ]}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.$refs.zkDialog.visibled(false)}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleCommand('zkSave')}}},[_vm._v("确 定")])],1)],1),_c('diy-dialog',{ref:"ZYueDialog",attrs:{"title":'学习内容授权',"width":"650px","center":""}},[_c('form-create',{ref:"txForm",staticStyle:{"text-align":"left"},attrs:{"label-width":"120px","components":[
        {
          name: 'form-item',
          props: {
            prop: 'unit_list_ids',
            label: '选择章节/条目',
            multiple: true,
            type: 'data',
            showKey: 'name',
            required: true,
            columnKey: 'key',
            dialog: {
              width: '650px',
              url: '/xapi/course.course/unitList',
              params: { course_id: _vm.course_id },
              table: {
                treeProps: {
                  hasChildren: 'hasChildren',
                  children: 'children',
                  isCheck: false,
                },
              },
            },
            whereColumns: [
              {
                name: 'form-item',
                props: {
                  prop: 'keyword',
                  label: '关键字',
                  placeholder: '请输入章节、条目名称',
                  style: { width: '350px' },
                },
              },
            ],
            columns: [
              // {
              //   prop: 'key',
              //   label: '编号',
              //   width: '120',
              //   align: 'center',
              //   fixed: 'left',
              // },
              { prop: 'title', label: '章节/条目名称', minWidth: '220' },
              {
                prop: 'create_date',
                label: '创建时间',
                width: '160',
                align: 'left',
              },
            ],
          },
        },
        {
          name: 'form-item',
          props: {
            prop: 'reason',
            label: '备注',
            // required: true,
            type: 'textarea',
          },
        },
      ]}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.$refs.ZYueDialog.visibled(false)}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleCommand('zyueSave', _vm.currentData)}}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }