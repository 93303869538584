<template>
  <div>
    <el-row>
      <el-col :span="12">
        
        <form-create
          v-model="where"
          :components="[
            {
              name: 'form-item',
              props: { prop: 'keyword', label: '学生名称' },
            },
          ]"
          inline
          :labelWidth="'70px'"
        >
          <el-form-item class="operate" slot="after">
            <el-button
              type="primary"
              size="small"
              @click="$refs.table.reset(where)"
              >搜索</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="$refs.table.reset((where = { status: null }))"
              >重置</el-button
            >
          </el-form-item>
        </form-create>
      </el-col>
      <el-col style="text-align: right" :span="12">
        <div v-if="courseInfo.is_free + '' !== 0 + ''">
          添加学生白名单：<el-button
            :disabled="!$isPowers('courseStudyCreate')"
            size="small"
            type="primary"
            @click="
              $refs.dialog.visibled(
                (dialogData = { title: '添加白名单', data: {} }) && true
              )
            "
            >添加白名单</el-button
          >
        </div>
        <div v-else>&nbsp;</div>

      </el-col>
    </el-row>
    <com-table
      :columns="[
        { prop: '', label: '电话号码', width: '150' ,align: 'center' },
        { prop: 'nickname', label: '学生姓名',align: 'center' },
        // {prop:'typeStr',label:'模式',width:'160'},
        { prop: 'statusStr', label: '状态',align: 'center' },
        { prop: 'create_time', label: '创建时间', align: 'center' },
        { prop: 'price', label: '付费金额', align: 'center' },

        {
          fixed: 'right',
          label: '操作',
          width: '500',
          slot: 'action',
          align: 'center',
        },
      ]"
      :data="{ url: '/xapi/course.course/studentList' }"
      :where="where"
      ref="table"
    >
      <template slot="action" slot-scope="{ row }">
        <el-button-group>
          <!-- <el-button type="primary" v-if="row.status!=0 && row.status!=2" :disabled="!$isPowers('courseDrop')" @click="$refs.txDialog.visibled((currentData = {...row}) && true)" size="mini">退课</el-button> -->
          <el-button
            type="primary"
            v-if="row.status != 0 && row.status != 2"
            :disabled="!$isPowers('courseDrop')"
            @click="quitCourse(row)"
            size="mini"
            >退课</el-button
          >
          <el-button
            type="primary"
            v-if="row.status != 0 && row.status != 2"
            :disabled="!$isPowers('courseTransfer')"
            @click="$refs.zkDialog.visibled((currentData = { ...row }) && true)"
            size="mini"
            >转课</el-button
          >
          <el-button
            v-if="row.status == 1"
            :disabled="!$isPowers('courseStudyDel')"
            @click="
              $handleConfirm(
                '您确定将学生「' + row.nickname + '」踢出白名单吗?',
                '/xapi/course.course/studentDel',
                { ...$route.params, user_id: row.id },
                () => {
                  $refs.table.reload();
                },
                { title: '踢出白名单' }
              )
            "
            type="primary"
            size="mini"
            >踢出白名单</el-button
          >

          <!-- <el-button type="primary" :disabled="!$isPowers('courseStudyEdit') || row.type===''" size="mini" @click="$refs.dialog.visibled((dialogData = {title:'学生编辑',data:{user_id:row.id,...row}}) && true)">编辑</el-button> -->
          <el-button
            type="primary"
            v-if="row.status == 1 || row.type == ''"
            :disabled="!$isPowers('courseStudySpeed')"
            size="mini"
            @click="
              $handleRoute(
                { study_user_id: row.id },
                'teachingCourseStudySpeed'
              )
            "
            >查看学习进度</el-button
          >
          <el-button
            type="primary"
            v-if="row.status == 2"
            :disabled="!$isPowers('courseStudyDel')"
            size="mini"
            @click="
              $handleConfirm(
                '你确定删除此学生吗？',
                '/xapi/course.course/studentDel',
                { ...$route.params, user_id: row.id },
                () => {
                  $refs.table.reload();
                }
              )
            "
            >删除</el-button
          >
          <el-button
            v-if="isTeach && courseInfo.learn_mode + '' === '2'"
            type="primary"
            :disabled="!$isPowers('courseStudyZYue')"
            @click="
              $refs.ZYueDialog.visibled((currentData = { ...row }) && true)
            "
            size="mini"
          >
            <!-- 章节/条目直阅 -->
            学习内容授权
          </el-button>
        </el-button-group>
      </template>
    </com-table>
    <diy-dialog ref="dialog" :title="dialogData.title" width="550px" center>
      <student-save
        ref="studentSave"
        :course_id="course_id"
        v-model="dialogData.data"
        @success="
          $refs.table.reload();
          $refs.dialog.visibled(false);
        "
      >
        <span slot="after"> </span>
      </student-save>
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.dialog.visibled(false)">取 消</el-button>
        <el-button
          type="primary"
          @click="$refs.studentSave.handleCommand('save')"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
    <diy-dialog ref="txDialog" :title="'退课'" width="650px" center>
      <form-create
        ref="txForm"
        style="text-align: left"
        label-width="100px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'leave_type',
              label: '退课方式',
              required: true,
              type: 'radio',
              defaultValue: 1,
              data: {
                url: '/xapi/course.course/studentOptions/type/leave_type',
              },
              dataMethod(data) {
                data = data.map((d) => {
                  d.disabled =
                    (!Number(currentData.price) > 0 ||
                      currentData.price == null) &&
                    ['2', '3'].indexOf(d.value) > -1;
                  return d;
                });
              },
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'refund_price',
              label: '退款金额',
              required: true,
              disabled: false,
              value: 0,
              type: 'number',
              max: Number(currentData.price),
            },
            condition: '{{leave_type}}==2',
            hidden: isTeach === false,
            slots: { after: '元' },
          },
          {
            name: 'form-item',
            props: {
              prop: 'refund_price',
              label: '退款金额',
              disabled: true,
              value: currentData.price,
              type: 'number',
              max: parseFloat(currentData.price),
            },
            condition: '{{leave_type}}==3',
            hidden: isTeach === false,
            slots: { after: '元' },
          },
          {
            name: 'form-item',
            props: {
              prop: 'leave_reason',
              label: '退课理由',
              required: true,
              type: 'textarea',
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'contact_mobile',
              label: '联系方式',
              required: true,
            },
            hidden: isTeach === true,
          },
        ]"
      >
      </form-create>
      <!-- <form-create ref="txForm" style="text-align: left;" label-width="100px" :components="[
                {name:'form-item',props:{prop:'leave_type',label:'退学方式',required:true,type:'radio',defaultValue:1,
                data:[
              {label:'男',value:'1'},
              {label:'女',value:'2'},
              {label:'保密',value:'3'},
            ]}},
                {name:'form-item',props:{prop:'refund_price',label:'退款金额',required:true,disabled:false,value:0,type:'number',max: Number(currentData.price) },condition:'{{leave_type}}==2',hidden:isTeach===false,slots:{after:'元'}},
                {name:'form-item',props:{prop:'refund_price',label:'退款金额',disabled:true,value: currentData.price,type:'number',max:parseFloat(currentData.price)},condition:'{{leave_type}}==3',hidden:isTeach===false,slots:{after:'元'}},
                {name:'form-item',props:{prop:'leave_reason',label:'退学理由',required:true,type:'textarea'}},
                {name:'form-item',props:{prop:'contact_mobile',label:'联系方式',required:true},hidden:isTeach===true},
      ]"
      > </form-create> -->
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.txDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('txSave')"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
    <diy-dialog ref="zkDialog" :title="'转课'" width="650px" center>
      <form-create
        ref="zkForm"
        style="text-align: left"
        label-width="100px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'transfer_course_id',
              label: '选择课程',
              required: true,
              type: 'select',
              data: {
                url: '/xapi/course.course/myList',
                labelOptions: { value: 'id', label: 'course_name' },
                params: { course_id, is_plan: 0, is_up: 1 },
              },
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'transfer_reason',
              label: '转课理由',
              required: true,
              type: 'textarea',
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'contact_mobile',
              label: '联系方式',
              required: true,
            },
            hidden: isTeach === true,
          },
        ]"
      >
      </form-create>
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.zkDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('zkSave')"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
    <diy-dialog ref="ZYueDialog" :title="'学习内容授权'" width="650px" center>
      <form-create
        ref="txForm"
        style="text-align: left"
        label-width="120px"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'unit_list_ids',
              label: '选择章节/条目',
              multiple: true,
              type: 'data',
              showKey: 'name',
              required: true,
              columnKey: 'key',
              dialog: {
                width: '650px',
                url: '/xapi/course.course/unitList',
                params: { course_id },
                table: {
                  treeProps: {
                    hasChildren: 'hasChildren',
                    children: 'children',
                    isCheck: false,
                  },
                },
              },
              whereColumns: [
                {
                  name: 'form-item',
                  props: {
                    prop: 'keyword',
                    label: '关键字',
                    placeholder: '请输入章节、条目名称',
                    style: { width: '350px' },
                  },
                },
              ],
              columns: [
                // {
                //   prop: 'key',
                //   label: '编号',
                //   width: '120',
                //   align: 'center',
                //   fixed: 'left',
                // },
                { prop: 'title', label: '章节/条目名称', minWidth: '220' },
                {
                  prop: 'create_date',
                  label: '创建时间',
                  width: '160',
                  align: 'left',
                },
              ],
            },
          },
          {
            name: 'form-item',
            props: {
              prop: 'reason',
              label: '备注',
              // required: true,
              type: 'textarea',
            },
          },
        ]"
      >
      </form-create>
      <div slot="footer" style="display: flex; justify-content: space-between">
        <el-button @click="$refs.ZYueDialog.visibled(false)">取 消</el-button>
        <el-button
          type="primary"
          @click="handleCommand('zyueSave', currentData)"
          >确 定</el-button
        >
      </div>
    </diy-dialog>
  </div>
</template>

<script>
import DiyDialog from "@/components/com/diyDialog";
import StudentSave from "@/views/api/teaching/course/studentSave";
export default {
  name: "teachingCourseStudy",
  components: { StudentSave, DiyDialog },
  props: ["course_id"],
  data() {
    return {
      dialogData: { title: "", data: {} },
      where: { status: null },
      courseInfo: {},
      currentData: {},
      isTeach: false,
      isStudent: false,
    };
  },
  methods: {
    routeUpdate() {
      console.log(this.course_id, "course_id");
      // return
      if (this.course_id > 0) {
        this.$http
          .post("/xapi/course.course/detail", { id: this.course_id })
          .then(({ data, isOnlyField }) => {
            data.is_free =
              data.is_free === undefined
                ? data.price > 0
                  ? 1
                  : 0
                : data.is_free;
            this.courseInfo = data;
            this.isTeach = data.isTeach === true;
            this.isStudent = data.isStudent === true;
          });
      }
    },
    handleCommandZyueSave() {
      return this.$refs.txForm
        ? this.$refs.txForm.validate((form, valid) => {
            if (valid) {
              return this.$http
                .post("/xapi/course.course/studentZyue", {
                  course_id: this.course_id,
                  ...form,
                  user_id: this.currentData.id,
                })
                .then(({ msg }) => {
                  this.$handleMessage(msg || "操作成功！", "success", () => {
                    this.$refs.ZYueDialog.visibled(false);
                  });
                });
            }
          })
        : null;
    },
    handleCommandTxSave() {
      return this.$refs.txForm
        ? this.$refs.txForm.validate((form, valid) => {
            if (valid) {
              return this.$http
                .post("/xapi/course.course/studentTx", {
                  course_id: this.course_id,
                  refund_price: this.$refs.txForm.form.refund_price,
                  price: this.currentData.price,
                  ...form,
                  user_id: this.currentData.id,
                })
                .then(({ msg }) => {
                  this.$handleMessage(msg || "退课成功！", "success", () => {
                    this.$refs.table.reload();
                    this.$refs.txDialog.visibled(false);
                  });
                });
            }
          })
        : null;
    },
    handleCommandZkSave() {
      return this.$refs.zkForm
        ? this.$refs.zkForm.validate((form, valid) => {
            if (valid) {
              return this.$http
                .post("/xapi/course.course/studentZk", {
                  course_id: this.course_id,
                  ...form,
                  user_id: this.currentData.id,
                })
                .then(({ msg }) => {
                  this.$handleMessage(msg || "转课成功！", "success", () => {
                    this.$refs.table.reload();
                    this.$refs.zkDialog.visibled(false);
                  });
                });
            }
          })
        : null;
    },
    //退课
    quitCourse(e) {
      console.log(e, "eeeeeeee");
      if(e.is_change==1){
        this.$confirm('该学生退课是否涉及退费，请您仔细确认核实。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log("确定");
          this.$refs.txDialog.visibled((this.currentData = { ...e }) && true)
        }).catch(() => {
          console.log("取消");
        });
      }else{
        this.$refs.txDialog.visibled((this.currentData = { ...e }) && true)

      }
    },
  },
};
</script>

<style scoped>
</style>